import { Spinner } from "@blueprintjs/core"
import React, { useMemo, useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { useDebouncedCallback } from "use-debounce"

import styles from "./FeatureFlags.module.css"

import { FeatureFlags_gql$key } from "./__generated__/FeatureFlags_gql.graphql"

import { sortAlphaNumeric } from "~/helpers/sorting-helpers"

import { subscriptionPlanUpdateDevRelay } from "~/mutations/Subscription"

import ServerFlagsProvider from "~/ServerFlags/ServerFlagsProvider"
import { getSettings, setSetting } from "~/localsettings"

import { FeatureAccountCheckbox } from "./FeatureAccountCheckbox"

export type FeatureFlagSort = "dateAsc" | "dateDesc" | "nameAsc" | "nameDesc"

type Option = {
  value: string
  label: string
}

type Props = {
  gql: FeatureFlags_gql$key
}

// These are all hard coded from the values that are in the Runn test Chargebee
// account. They are case sensitive
const PLAN_OPTIONS: Option[] = [
  {
    value: "runn-free",
    label: "Free (Legacy)",
  },
  {
    value: "runn-pro-monthly",
    label: "Pro (Legacy)",
  },
  {
    value: "runn-free-usd-monthly",
    label: "Free USD Monthly",
  },
  {
    value: "runn-free-USD-yearly",
    label: "Free USD Yearly",
  },
  {
    value: "runn-starter-USD-monthly",
    label: "Starter USD Monthly",
  },
  {
    value: "runn-professional-USD-monthly",
    label: "Professional USD Monthly",
  },
  {
    value: "runn-professional-USD-yearly",
    label: "Professional USD Yearly",
  },
  {
    value: "runn-premium-USD-yearly",
    label: "Premium USD Yearly",
  },
  {
    value: "runn-enterprise-USD-yearly",
    label: "Enterprise USD Yearly",
  },
]

const FeatureFlags = (props: Props) => {
  const data = useFragment(
    graphql`
      fragment FeatureFlags_gql on query_root {
        features {
          id
          name
          ...FeatureAccountCheckbox_feature
        }
        billing(syncSubscription: false) {
          subscription {
            id
            plan {
              id
              itemPriceId
            }
          }
        }
        ...ServerFlagsProvider_gql
      }
    `,
    props.gql,
  )

  const subscriptionPlan = data.billing.subscription?.plan?.itemPriceId
  const [isChangingPlan, setIsChangingPlan] = useState(false)
  const defaultSort = getSettings().featureFlagSortBy
  const [sortBy, setSortBy] = useState<FeatureFlagSort>(
    defaultSort || "nameAsc",
  )
  const [search, setSearch] = useState("")
  const debouncedOnChange = useDebouncedCallback((val: string) => {
    setSearch(val)
  }, 200)

  const handleSearch = (e) => {
    debouncedOnChange(e.target.value)
  }

  const sortedFeatures = useMemo(() => {
    const filteredFeatures = data.features.filter((feature) =>
      feature.name.toLowerCase().includes(search.toLowerCase()),
    )

    switch (sortBy) {
      case "dateAsc":
        return [...filteredFeatures].reverse()
      case "dateDesc":
        return filteredFeatures
      case "nameAsc":
        return [...filteredFeatures].sort((a, b) =>
          sortAlphaNumeric(a.name, b.name),
        )
      case "nameDesc":
        return [...filteredFeatures].sort((a, b) =>
          sortAlphaNumeric(b.name, a.name),
        )
    }
  }, [sortBy, data.features, search])

  const handleSort = (value: FeatureFlagSort) => {
    setSetting("featureFlagSortBy", value)
    setSortBy(value)
  }

  const currentPlan = PLAN_OPTIONS.find(
    (o) => o.value === subscriptionPlan,
  )?.value

  const handleChangePlan = async (value: Option["value"]) => {
    setIsChangingPlan(true)
    await subscriptionPlanUpdateDevRelay({
      input: { planId: value },
    })
    setIsChangingPlan(false)
    window.location.reload()
  }

  const sortOptions = [
    { value: "nameAsc", label: "A to Z" },
    { value: "nameDesc", label: "Z to A" },
    { value: "dateAsc", label: "Date: Newest to Oldest" },
    { value: "dateDesc", label: "Date: Oldest to Newest" },
  ]

  return (
    <>
      <ServerFlagsProvider gql={data}>
        <div>
          <strong>
            Enable and disable feature flags for the current account
            (features_account)
          </strong>
          <div className={styles.container}>
            <div>
              Search:
              <input
                autoFocus
                onChange={handleSearch}
                placeholder="Feature flag name"
              ></input>
            </div>
            <div>
              Sort By:
              <select
                onChange={(e) => handleSort(e.target.value as FeatureFlagSort)}
                value={sortBy}
              >
                {sortOptions.map((o) => (
                  <option key={o.value} value={o.value}>
                    {o.label}
                  </option>
                ))}
              </select>
            </div>
            {subscriptionPlan && (
              <div>
                Plan:
                <div>
                  <select
                    onChange={(e) => handleChangePlan(e.target.value)}
                    defaultValue={currentPlan || "Unknown Plan"}
                    className={styles.planSelect}
                  >
                    {PLAN_OPTIONS.map((o) => (
                      <option key={o.value} value={o.value}>
                        {o.label}
                      </option>
                    ))}
                  </select>
                </div>
                {isChangingPlan && <Spinner size={20} />}
              </div>
            )}
          </div>
          {sortedFeatures.map((feature) => (
            <FeatureAccountCheckbox key={feature.id} feature={feature} />
          ))}
        </div>
      </ServerFlagsProvider>
    </>
  )
}

export default FeatureFlags
