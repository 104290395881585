import { Icon } from "@blueprintjs/core"
import cc from "classcat"
import { Feature, useFeature } from "flagged"
import React, { useLayoutEffect, useState } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

import styles from "./Navigation.module.css"

import { NavigationQuery } from "./__generated__/NavigationQuery.graphql"

import { isNewTabClick } from "~/helpers/general-helpers"
import { Permissions } from "~/helpers/permissions"
import { willBannerRender } from "~/helpers/planner-helpers"
import { getReportList } from "~/helpers/reports-helpers"

import MenuItem from "~/common/MenuItem"
import PaidFeatureMenuItemContainer from "~/common/PaidFeatureMenuItemContainer"
import Button from "~/common/buttons/Button"
import { SimpleLabel } from "~/common/labels"
import {
  ChevronDown,
  ClientIcon,
  CustomFieldsIcon,
  FaceIcon,
  PersonCircleIcon,
  ProfitIcon,
  ProjectClipboardIcon,
  ProjectTemplateIcon,
  SharedFolderIcon,
  SkillIcon,
  SocialIcon,
  WorkstreamIcon,
} from "~/common/react-icons"

import { ChargebeeFeatures } from "~/Entitlements/plansAndFeatures"
import {
  useEntitlementQuantity,
  useEntitlementSwitch,
  useIsInFreePlan,
  useIsInTrial,
} from "~/Entitlements/useEntitlements"
import PermissionsProvider, {
  Can,
  usePermissionsContext,
} from "~/Permissions/PermissionsProvider"
import { withRelayPageContainerNoLoading } from "~/RelayPageContainer"
import { ReportType } from "~/reports/types"
import useReportVersion from "~/reports_v2/hooks/useReportVersion"

export type NavigationSettings = {
  timesheetsEnabled: boolean
}

export type Props = {
  pageToggle?: (type: "projects" | "people") => void
  activePage?: string
  permissions: Permissions
  settings: NavigationSettings
}

const defaultSettings: NavigationSettings = {
  timesheetsEnabled: true,
}

const QUERY = graphql`
  query NavigationQuery {
    current_user {
      id
      permissions
      account {
        id
        account_type
      }
      ...PermissionsProvider_user
    }
  }
`

const navItem = (active: boolean) => cc({ "nav-item": true, active })
const navLabel = (hovered: boolean) => cc({ "nav-label": true, hovered })

const Navigation = (props: Props) => {
  const { pageToggle, activePage, permissions } = props

  const data = useLazyLoadQuery<NavigationQuery>(QUERY, {})
  const { current_user } = data

  const { subject, isAdmin, isEditor, isViewerAll } = usePermissionsContext({
    user: current_user,
  })
  const [showDropdown, setShowDropdown] = useState(null)
  const { settings } = props

  const mergedSettings = {
    ...defaultSettings,
    ...settings,
  }

  const { version, setVersionAndReload, isReportsV2Enabled } =
    useReportVersion()

  const entitlementsEnabled = useFeature("subscription_entitlements")
  const isInTrial = useIsInTrial()
  const isInFreePlan = useIsInFreePlan()

  // If entitlements are on and the user is in a trial then we do not render
  // certain menu items in their normal spot. We render them in an upselling
  // section. This boolean helps manage that
  const entitledAndInTrial = entitlementsEnabled && isInTrial

  const isFreeOrTrial = isInFreePlan || isInTrial

  const entitledToProjectTemplates = useEntitlementSwitch(
    ChargebeeFeatures.projectTemplates,
  )
  const entitledToWorkstreams = useEntitlementSwitch(
    ChargebeeFeatures.workstreams,
  )
  const entitledToAccountViews = useEntitlementSwitch(
    ChargebeeFeatures.accountViews,
  )
  const customFieldsEntitledQuantity = useEntitlementQuantity(
    ChargebeeFeatures.customFields,
  )

  const showUpsellingSection = entitlementsEnabled && isFreeOrTrial

  const isBannerActive = willBannerRender(
    current_user.permissions,
    current_user.account.account_type,
    isFreeOrTrial,
  )

  useLayoutEffect(() => {
    if (isBannerActive) {
      document.body.classList.add("banner-padding")
    } else {
      document.body.classList.remove("banner-padding")
    }
  }, [isBannerActive])

  const homeUrl = document.location
  const projectParams = new URLSearchParams(homeUrl.search)
  const peopleParams = new URLSearchParams(homeUrl.search)
  projectParams.set("activePage", "projects")
  peopleParams.set("activePage", "people")

  const changeUrl = (e, page) => {
    if (!isNewTabClick(e)) {
      e.preventDefault()
      if (pageToggle) {
        return pageToggle(page)
      }

      const homeParams = new URLSearchParams(homeUrl.search)
      homeParams.set("activePage", page)
      document.location.href = `${homeUrl.origin}?${homeParams}`
    }
  }

  const renderManage = () => {
    const showManageDD = showDropdown === "manage"
    const managed = [
      "manages",
      "projects",
      "people",
      "clients",
      "roles",
      "rate_cards",
      "tags",
      "skills",
      "teams",
      "users",
      "project_templates",
      "views",
      "custom_fields",
      "workstreams",
    ]
    const manageActive = !!managed.find((page) => page === activePage)

    return (
      <li
        data-test="nav-manage"
        className={navItem(manageActive)}
        onMouseEnter={() => setShowDropdown("manage")}
        onMouseLeave={() => setShowDropdown(null)}
      >
        <div className={navLabel(showManageDD)} style={{ marginTop: "-1px" }}>
          <a href="/manage">Manage</a>
          <ChevronDown alt="open" />
        </div>
        {showManageDD && (
          <ul className={styles.navDropdown}>
            <Can I="view" this={subject("Project")}>
              <MenuItem
                text="Projects"
                icon={<ProjectClipboardIcon />}
                href="/projects"
              />
            </Can>
            <Can I="view" this={subject("Person")}>
              <MenuItem text="People" icon={<FaceIcon />} href="/people" />
              <div className={styles.divider} />
            </Can>
            <Can I="view" this={subject("Client")}>
              <MenuItem text="Clients" icon={<ClientIcon />} href="/clients" />
            </Can>
            <Can I="view" this={subject("RateCard")}>
              <MenuItem
                text="Rate Cards"
                icon={<ProfitIcon />}
                href="/rate_cards"
              />
            </Can>
            {entitledToProjectTemplates && !entitledAndInTrial && (
              <Can I="view" this={subject("ProjectTemplate")}>
                <MenuItem
                  text="Project Templates"
                  icon={<ProjectTemplateIcon />}
                  href="/project_templates"
                />
              </Can>
            )}
            {entitledToWorkstreams && (
              <Feature name="workstreams">
                <Can I="view" this={subject("Workstream")}>
                  <MenuItem
                    text={"Workstreams"}
                    icon={<WorkstreamIcon />}
                    href="/workstreams"
                  />
                </Can>
              </Feature>
            )}
            <div className={styles.divider} />
            <Can I="view" this={subject("Role")}>
              <MenuItem
                text="Roles"
                icon={<PersonCircleIcon />}
                href="/roles"
              />
            </Can>
            <Can I="view" this={subject("Skill")}>
              <MenuItem text="Skills" icon={<SkillIcon />} href="/skills" />
            </Can>
            <Can I="view" this={subject("Team")}>
              <MenuItem text="Teams" icon={<SocialIcon />} href="/teams" />
            </Can>
            <div className={styles.divider} />
            {customFieldsEntitledQuantity > 0 && !entitledAndInTrial && (
              <>
                {/* Manage is a CRUD alias reserved for admins/editors */}
                <Can I="manage" this={subject("CustomField")}>
                  <MenuItem
                    text={<span>Custom Fields</span>}
                    icon={<CustomFieldsIcon />}
                    href="/custom_fields"
                  />
                </Can>
              </>
            )}
            <Can I="view" this={subject("Tag")}>
              <MenuItem
                text="Tags"
                icon={<Icon icon="tag" color="var(--midnight)" />}
                href="/tags"
              />
            </Can>
            <Can I="view" this={subject("User", current_user)}>
              {(isAdmin || isEditor) && (
                /* Can't use can('manage', 'User') here since everyone can manage themselves */
                <MenuItem
                  text="Users"
                  icon={<SharedFolderIcon />}
                  href="/users"
                />
              )}
            </Can>
            {entitledToAccountViews && (
              <Feature name="pre_filtered_views">
                {(isAdmin || isEditor || isViewerAll) && (
                  <MenuItem
                    text={"Views"}
                    icon={<Icon icon="new-layers" color="var(--midnight)" />}
                    href="/views"
                  />
                )}
              </Feature>
            )}

            {showUpsellingSection && (
              <PaidFeatureMenuItemContainer
                customStyles={{ marginBottom: "-5px" }}
                roundedButton
              >
                <Can I="view" this={subject("ProjectTemplate")}>
                  <MenuItem
                    text="Project Templates"
                    icon={<ProjectTemplateIcon />}
                    href="/project_templates"
                    softDisabled={!entitledToProjectTemplates}
                  />
                </Can>

                <Can I="manage" this={subject("CustomField")}>
                  <MenuItem
                    text={<span>Custom Fields</span>}
                    icon={<CustomFieldsIcon />}
                    href="/custom_fields"
                    softDisabled={!customFieldsEntitledQuantity}
                  />
                </Can>
                <Feature name="workstreams">
                  <Can I="view" this={subject("Workstream")}>
                    <MenuItem
                      text={<>Workstreams</>}
                      icon={<WorkstreamIcon />}
                      href="/workstreams"
                      softDisabled={!entitledToWorkstreams}
                    />
                  </Can>
                </Feature>
                <Feature name="pre_filtered_views">
                  {(isAdmin || isEditor || isViewerAll) && (
                    <MenuItem
                      text={"Views"}
                      icon={<Icon icon="new-layers" color="var(--midnight)" />}
                      href="/views"
                      softDisabled={!entitledToAccountViews}
                    />
                  )}
                </Feature>
              </PaidFeatureMenuItemContainer>
            )}
          </ul>
        )}
      </li>
    )
  }
  const renderReportNavList = (type: ReportType) =>
    getReportList(mergedSettings, type).map(
      ({ name, link, navigationName }) => (
        <MenuItem
          className={styles.menuItemNoIcon}
          key={name}
          text={navigationName}
          href={link}
        />
      ),
    )

  const renderReports = () => {
    if (version === "v2") {
      return (
        <li
          data-test="nav-reports_v2"
          className={navItem(activePage === "reports_v2")}
          onMouseEnter={() => setShowDropdown("reports_v2")}
          onMouseLeave={() => setShowDropdown(null)}
        >
          <div className={navLabel(false)} style={{ marginTop: "-1px" }}>
            <a href="/reports">Reports</a>
          </div>
        </li>
      )
    }

    const showReportsDD = showDropdown === "reports"

    return (
      <li
        data-test="nav-reports"
        className={navItem(activePage === "reports")}
        onMouseEnter={() => setShowDropdown("reports")}
        onMouseLeave={() => setShowDropdown(null)}
      >
        <div className={navLabel(showReportsDD)} style={{ marginTop: "-1px" }}>
          <a href="/reports">Reports</a>
          <ChevronDown alt="open" />
        </div>
        {showReportsDD && (
          <ul className={styles.navDropdown}>
            <li className={styles.heading}>PROJECTS</li>
            {renderReportNavList("projects")}
            <div className={styles.divider} />
            <li className={styles.heading}>PEOPLE</li>
            {renderReportNavList("people")}
            {isReportsV2Enabled && (
              <>
                <div className={styles.divider} />
                <li className={styles.betaContainer}>
                  <SimpleLabel type="beta" text="Beta" />
                  <p className={styles.betaTitle}>Try Reports (Beta)</p>
                  <p className={styles.betaText}>
                    You can switch back to these reports at any time
                  </p>
                  <Button
                    text="Switch to Beta"
                    onClick={() => setVersionAndReload("v2")}
                    intent="primary"
                    outlined={false}
                  />
                </li>
              </>
            )}
          </ul>
        )}
      </li>
    )
  }

  return (
    <PermissionsProvider user={current_user}>
      <ul
        data-component="Navigation"
        className={cc([styles.navigation, styles.bannerLayout])}
        data-banner-active={isBannerActive}
      >
        <Can I="view" this={subject("Planner")}>
          <li className={navItem(activePage === "projects_planner")}>
            <a
              className={`nav-label`}
              style={{ marginTop: "-1px" }}
              href={`/?${projectParams}`}
              onClick={(e) => changeUrl(e, "projects")}
            >
              Projects
            </a>
          </li>
          <li className={navItem(activePage === "people_planner")}>
            <a
              className={`nav-label`}
              style={{ marginTop: "-1px" }}
              href={`/?${peopleParams}`}
              onClick={(e) => changeUrl(e, "people")}
            >
              People
            </a>
          </li>
        </Can>
        {!["viewer_basic", "timesheet_only"].includes(permissions.type) &&
          renderManage()}
        {!["viewer_basic", "timesheet_only"].includes(permissions.type) &&
          renderReports()}
      </ul>
    </PermissionsProvider>
  )
}

export default withRelayPageContainerNoLoading(Navigation)
